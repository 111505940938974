import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import SpecTable from 'components/Products/IN-Motion/300/SpecTable';
import ComponentTable from 'components/Products/IN-Motion/300/ComponentTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/300/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "IN-MOTION is a passive (PIR) motion detector. This sensor is the perfect extension for your ip camera in order to minimize false alarms.",
  "image": "../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-Motion 300 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 300 is the perfect extension for your ip camera in order to minimize false alarms. It can only be used for your indoor surveillance.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/300/' locationFR='/fr/Products/IN-Motion/300/' crumbLabel="300" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion",
        "aria-label": "in motion permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion`}</h1>
    <h2 {...{
      "id": "in-motion-300",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-motion-300",
        "aria-label": "in motion 300 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 300`}</h2>
    <p><strong parentName="p">{`Passive Infrared Motion Detector`}</strong></p>
    <p>{`IN-MOTION is a passive (PIR) motion detector. This sensor is the perfect extension for your ip camera in order to minimize false alarms. While the camera is using a software based motion detection which compares each picture (frame) with each other to identify a motion, the PIR detector is only detecting humans or bigger animals which will release heat from the body's. This way a tree which is moving in the wind or light which gets turned on and off will not cause an alarm of your ip camera. The IN-MOTION motion detector will not detect this kind of movement as an alarm which will lower the amount of false alarms enormously.`}</p>
    <p>{`The scope of the delivery sums up everything you will need to use the sensor with your INSTAR IP camera (as long as the camera model comes with an Alarm I/O - please check the cameras technical specification):`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a1ba01499d4c0c758282756adcd8404/f93b5/IN-Motion300_00.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdSlMU1nQtEKg//EABwQAAICAgMAAAAAAAAAAAAAAAEDABMCERIhMf/aAAgBAQABBQK022bNffARzK3LORXM/Z//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAdEAACAgIDAQAAAAAAAAAAAAABEQACECISIVJx/9oACAEBAAY/Alx1TcCh2OLixuPClTYIrvFfuP/EABsQAQACAwEBAAAAAAAAAAAAAAEAESFBYSAx/9oACAEBAAE/Ia3qx3CgojuN0+jcwVmYMkOsuZIR4D//2gAMAwEAAgADAAAAENDHPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQACAgIDAAAAAAAAAAAAAAERMQAhUWEgQYH/2gAIAQEAAT8QVHSRFFNcVm6sjtY8ZKMkzrKSB6BrBAFVg4vtn5mt84RDG/BP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a1ba01499d4c0c758282756adcd8404/e4706/IN-Motion300_00.avif 230w", "/en/static/9a1ba01499d4c0c758282756adcd8404/e47c0/IN-Motion300_00.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a1ba01499d4c0c758282756adcd8404/a0b58/IN-Motion300_00.webp 230w", "/en/static/9a1ba01499d4c0c758282756adcd8404/c85cb/IN-Motion300_00.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a1ba01499d4c0c758282756adcd8404/e83b4/IN-Motion300_00.jpg 230w", "/en/static/9a1ba01499d4c0c758282756adcd8404/f93b5/IN-Motion300_00.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a1ba01499d4c0c758282756adcd8404/f93b5/IN-Motion300_00.jpg",
              "alt": "IN-Motion 300 Motion Detection Sensors",
              "title": "IN-Motion 300 Motion Detection Sensors",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-Motion 300 PIR Motion Detector for indoor IP Cameras.`}</p>
    <ul>
      <li parentName="ul">{`1x IN-MOTION 300`}</li>
      <li parentName="ul">{`1x 4m signal cable`}</li>
      <li parentName="ul">{`1x power supply (12V / 3A) with 3m cable`}</li>
      <li parentName="ul">{`1x set of dowels and screws`}</li>
      <li parentName="ul">{`1x installation manual`}</li>
    </ul>
    <h3 {...{
      "id": "how-does-a-motion-detector-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-does-a-motion-detector-work",
        "aria-label": "how does a motion detector work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does a motion detector work?`}</h3>
    <h4 {...{
      "id": "what-is-pir",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what-is-pir",
        "aria-label": "what is pir permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is PIR?`}</h4>
    <p>{`PIR stands for Passive Infrared or Pyroelectric Infrared. PIR sensors can be defined as detectors of warm-bodied targets in motion. A stationary target can not be detected. To detect a moving target, the target must have a surface temperature that is substantially different than the surrounding ambient temperature. Although the usual targets of a PIR detector are warm-bodied (mammals, birds), the PIR sensor will also detect a moving target that has a surface temperature that is significantly lower than the ambient temperature. As long as the target is large enough, and its surface temperature is detectably different than the ambient (higher or lower) - and the target is moving - it can be detected by a PIR sensor.`}</p>
    <h4 {...{
      "id": "main-features-of-the-in-motion-300",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#main-features-of-the-in-motion-300",
        "aria-label": "main features of the in motion 300 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Main Features of the IN-Motion 300`}</h4>
    <ul>
      <li parentName="ul">{`Internal logic control to avoid false alarms`}</li>
      <li parentName="ul">{`Auto temperature compensation`}</li>
      <li parentName="ul">{`Pulse count adjustment`}</li>
      <li parentName="ul">{`White light interference compensation`}</li>
      <li parentName="ul">{`RF interference comp. (20V/m-1GHz)`}</li>
      <li parentName="ul">{`Wide angle Fresnel lense`}</li>
      <li parentName="ul">{`Ceiling installation`}</li>
      <li parentName="ul">{`Alarm output N.C. / N.O.`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b0169ece7e53b49a4008e2cf844bb352/13ae7/IN-Motion300_00s.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACLUlEQVQoz3WT70tTURjHLyZLZ3NOvQ6JonJqEUZBvulF9CYiazAoCjURFIkoqYyC7EUW1l8QYpRQoiTkKwP7Yb/D0KWbOVAGphvDom02nY7h7rZP3Gu3dld94YHnHM75nO95znMEfimZTGoikUgoISsajbIUDuP3+wkEg0QiEeLxuGafmgvpE+pCVcEfIXzz37A7nLx6/4GPdjv28XFmPV5Ci0t/mRFSYaojOfd6PTx78Zy+x/2MORxMTLkZm/7Cu08TjEy48M1/Zc7r43sg+HuPLCF1IOvN29c0XzzP5WutXG27TuuNNs61XKDxdBP3urpYXF5Zi/AygYUQ0+4ZYrGY9sqqs+6eh+zeu4d9B/Zz50EP9/v66ejupbP3EVdutqPTZ9PQ1Eh0NUZ4JcJCaAnXlJtYTPoDVGs2OfmZbWUWLNvLMG8spryiAltNHXVnmjle38CuykpyjLmsN+jpuNvJqpTA6ZpixD6mqaMgxdfoA08GyNqgxyQWkpOXq+RCZgZChsA6XSaZWTqMBSb0RgOHjlQx6/UxM+dBSnltpYaqQ4fTgVhsVmAFZpFNJSUctFo5bLOxudSCqagQk1igHHCi+qSmE/7bNu23bylAnT6LvCKRYzW1nG25xJbycmVedmkwGZXbyJIkSQNTgOmvPPRyiOpTteyo2Em+WSTbmEt+kcjWUgtV1qMMPh1U1iXS+lfVP/tQlj/gx+F0Mjw8zKh9lDmPh/RflQ6TgT8B30G8DxC4qLwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0169ece7e53b49a4008e2cf844bb352/e4706/IN-Motion300_00s.avif 230w", "/en/static/b0169ece7e53b49a4008e2cf844bb352/7bd34/IN-Motion300_00s.avif 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b0169ece7e53b49a4008e2cf844bb352/a0b58/IN-Motion300_00s.webp 230w", "/en/static/b0169ece7e53b49a4008e2cf844bb352/a3432/IN-Motion300_00s.webp 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0169ece7e53b49a4008e2cf844bb352/81c8e/IN-Motion300_00s.png 230w", "/en/static/b0169ece7e53b49a4008e2cf844bb352/13ae7/IN-Motion300_00s.png 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b0169ece7e53b49a4008e2cf844bb352/13ae7/IN-Motion300_00s.png",
              "alt": "IN-Motion 300 Motion Detection Sensors",
              "title": "IN-Motion 300 Motion Detection Sensors",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-Motion 300 - A powerful and inconspicuous camera companion.`}</p>
    <h4 {...{
      "id": "general-considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#general-considerations",
        "aria-label": "general considerations permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General Considerations`}</h4>
    <p>{`Temperatures can often change dramatically in relatively short times. Large temperature changes can render the PIR sensor either more, or less, sensitive. As ambient temperatures rise to near 37ºC, the difference between the target and ambient temperature decreases - and the sensitivity of the sensor declines. As temperatures decrease the opposite is true and the sensor becomes more sensitive.`}</p>
    <p>{`As target and ambient temperatures become equal, the PIR sensor may no longer be able to detect a target. Large targets usually have enough variation in their surface temperature to allow the sensor to detect them - even when the ambient temperature is the same as their average body temperature.`}</p>
    <p>{`The IN-Motion PIR sensors compensate the PIR detector sensitivity according to the ambient temperature. This allows you to utilize the full range of the sensors capabilities - whenever and wherever they are needed!`}</p>
    <h3 {...{
      "id": "how-do-i-install-the-in-motion-300",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-install-the-in-motion-300",
        "aria-label": "how do i install the in motion 300 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I install the IN-Motion 300?`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c42bbf11051bad3a86107eef07ce0ca2/5a190/IN-Motion300_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACnElEQVQoz21SbU9SARi9P6GPttZatumHspnJNF5UQBC4oKSAioDzrTSDNG1qbThBY7WmpRv1qbJ0tWUvSy2apWjpcspM0YpMUJANRNJdXgy48DR2m1/q+fRs55xnZ+c5CPxvfvm8oXCI2HEcD4fDsVjsXxridDntmxsOpz24FwCAOCkGQTwY/B10OOwOhz0SiQBANBol9N5dr3vbvbG57t52IXm8rNSUYwkHD7x+84IghUKhhYX5kuJCSaGwUl4mzEdHR4bjd6Nxse6GprGhvqmh7nKzEuGy6dkZmcmJR14NDxFmtJr2RmW9pFjQ1q3qetDKEZFzWbTuvps44FgIe/bh7v1RzeN3Ov1QB1J3sbrxklImKxkzGgBgyjhBp1GK8lEqlZRGO0FmneLxGQppSVrq8SnjBAA062qzS1PyZOnC83TEtePCAhjmxzz+LYCY9rpaeJZLZ1J4bObI85eD/Y+EfEYOjUTJJF1tvQIAFst30/zcsnlpdn4G2Y8ujIdxiGo71VKpSCaXiEUCvb736YB2zEDTaaQ8Fqqsrfmb6H7aNrt13W5ds616dj0AMPDkoahIUCYRiSV8lM+6UMW5080/VyPlcnM7u9rjX8S8a9YfVtuq1baK9Ol7bvfcKldIx6ffA4DV/lOukOSjHJlCXCRE85jMTPIZMplEIafPmWYBwLPr7tReY2dlCAQ5iEpVdzr15OFDCZ8+fyTMjBnesnKyyuRiYQFPVixG+YwCPmewv59ANxw2ZX01g0rlsFGkvEKanJSYnHTUtDgHAEQlVsxmdUdbVaW8tqaqqUVlnBwnqhaHvi2p1S0VstJ7vbnIomXhq2V5xWL2BXz7fQQA/54fC2CRcGQnsBONRXEcJ6LyB3yTM0bTl2nnluEPvOvnu3k04QUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c42bbf11051bad3a86107eef07ce0ca2/e4706/IN-Motion300_01.avif 230w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/d1af7/IN-Motion300_01.avif 460w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/c9875/IN-Motion300_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c42bbf11051bad3a86107eef07ce0ca2/a0b58/IN-Motion300_01.webp 230w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/bc10c/IN-Motion300_01.webp 460w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/d00b9/IN-Motion300_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c42bbf11051bad3a86107eef07ce0ca2/81c8e/IN-Motion300_01.png 230w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/08a84/IN-Motion300_01.png 460w", "/en/static/c42bbf11051bad3a86107eef07ce0ca2/5a190/IN-Motion300_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c42bbf11051bad3a86107eef07ce0ca2/5a190/IN-Motion300_01.png",
              "alt": "IN-Motion 300 Motion Detection Sensors",
              "title": "IN-Motion 300 Motion Detection Sensors",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`These are the components of the IN-Motion 300 PIR motion detector.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ComponentTable mdxType="ComponentTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "how-do-i-connect-it-to-my-ip-camera-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-connect-it-to-my-ip-camera-",
        "aria-label": "how do i connect it to my ip camera  permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I connect it to my IP Camera ?`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf9651fea814ad798d4310688fe60048/5a190/IN-Motion300_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQklEQVQoz5WQu0oDQRSG530sfACLkEDQJpUELGy0UhBribFIpSCkULwkVQovEAKChViK1xCI3W42s6C5gZhJJsm4O7PJ7swREwxbKNGvOnDOfz74keu6rNezGGsTwm0bJqGUEgNbKQUASAhhmrhaqTTqNcH5aD0GQPljAHD9nN3MLF3mM4x3EOfcMAzTNAkh44sfhMMvSsHKQXQxGYhsTWdv08hxRLlsaJpWKBQwxrqul4Zoml55ffnotlmnzShhtEXfq9230v5JLJKYWk4G01cJ9HB/hzG2LKvZbLZ8EEK6Hepwqy/sgSO8Qd+TElS/WivuXcSL+AZAoblwaHdnOx7byD89AoCUckJhvhkFAzML0fnZcCh1fAgAruv+Vth3bdKTnpTel/n87HR9bTWdOsrlsn8x+0FSSkppo1H/V2wU/gQzrOgeaeOQFwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf9651fea814ad798d4310688fe60048/e4706/IN-Motion300_02.avif 230w", "/en/static/bf9651fea814ad798d4310688fe60048/d1af7/IN-Motion300_02.avif 460w", "/en/static/bf9651fea814ad798d4310688fe60048/c9875/IN-Motion300_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf9651fea814ad798d4310688fe60048/a0b58/IN-Motion300_02.webp 230w", "/en/static/bf9651fea814ad798d4310688fe60048/bc10c/IN-Motion300_02.webp 460w", "/en/static/bf9651fea814ad798d4310688fe60048/d00b9/IN-Motion300_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf9651fea814ad798d4310688fe60048/81c8e/IN-Motion300_02.png 230w", "/en/static/bf9651fea814ad798d4310688fe60048/08a84/IN-Motion300_02.png 460w", "/en/static/bf9651fea814ad798d4310688fe60048/5a190/IN-Motion300_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf9651fea814ad798d4310688fe60048/5a190/IN-Motion300_02.png",
              "alt": "IN-Motion 300 Motion Detection Sensors",
              "title": "IN-Motion 300 Motion Detection Sensors",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The camera´s alarm I/O consists of 4 pins - 1&2 are the alarm output and 3&4 are the alarm input. Use the pins 3&4 for (potential-free) sensor devices you want to use to trigger the camera´s alarm.`}</p>
    <p>{`The IN-Motion PIR Sensor is already connected to the power plug and the alarm output cable. Simply connect the signal cable to your INSTAR cameras alarm input connector. The image below shows the I/O bridge of IN-3005/3010 or 3011 - choose the ports 3 & 4 for the sensor cable and replug the bridge into the cameras I/O connector.`}</p>
    <p>{`The alarm input of your camera might look different depending on your camera model. Please refer to your user manual to identify the correct ports. You may choose either polarity when connecting the cable. After connecting the PIR sensor with your camera's signal input, please open the camera's web user interface. Go to the alarm settings and activate the external alarm input. Depending on how you set up your sensor (N.C. or N.O.) choose the Relay Position (HD cameras) / Trigger Level (VGA cameras) accordingly. In practical terms that means - choose the value that doesn't cause you a continuous alarm trigger even if nothing is happening in front of the sensor. You can use the IN-Motions alarm LED (red alarm indicator should light up when triggered, see table above #7) to test if the sensor is working. Testing it in it´s place of installation will allow you to adjust it to a suitable sensitivity.`}</p>
    <p>{`Triggering the PIR sensor should now trigger an alarm event inside of the camera. The trigger event will be treated just like an internal motion detection event by the camera. So all the settings in place for an alarm event (video recording, email notification, ftp upload, etc) can be triggered by the PIR sensor from now on.`}</p>
    <h3 {...{
      "id": "technical-specifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#technical-specifications",
        "aria-label": "technical specifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technical Specifications`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <SpecTable mdxType="SpecTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      